import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import config from 'data/config/config';

import fbShare from 'src/core/share/FacebookWebShare';
import liShare from 'src/core/share/LinkedInWebShare';

import * as actions from 'src/store/actions';

import './ShareDialog.scss';

import { isIOS, isAndroid } from 'src/core/util/browser';

export const COMPONENT_KEY = 'ShareDialog';
export const CONTAINER_DOM_ID = 'share-dialog-container';

const DEFAULT_DIALOG_PROPS = {
  // TODO: Export that to data/config/dialogsConfig ?
};

class ShareDialog extends PureComponent {
  state = {
    isDialogOpen: false,
  };

  shareOnFacebook = () => {
    fbShare(
      this.props.name,
      this.props.description,
      this.props.url,
      this.props.image,
      this.props.labels
    );
  };
  shareOnLinkedIn = () => {
    liShare(
      this.props.name,
      this.props.description,
      this.props.url,
      this.props.image,
      this.props.labels
    );
  };
  openLink(url, target) {
    window.open(url, target);
  }

  render() {
    if (!this.props.name || !this.props.url) {
      return null;
    }

    let encodedName = encodeURIComponent(this.props.name),
      encodedUrl = encodeURIComponent(this.props.url);

    let dialogProps = Object.assign({}, DEFAULT_DIALOG_PROPS, {
      open: this.props.isOpen,
      onClose: this.props.actions.hideShareDialog, // important, to update `open` value in all cases
    });

    let smsEncodedUrl = isIOS() ? encodedUrl : encodeURIComponent(encodedUrl);
    let varSeparator = isIOS() ? '&' : '?';

    let target = global.isCordovaContext ? '_system' : '_blank';

    return (
      <Dialog {...dialogProps}>
        <DialogTitle className="title-font modal-title">
          {this.props.labels.common.share}
        </DialogTitle>

        <div className="generic-modal-content share-buttons">
          {(isIOS() || isAndroid()) && (
            <a
              href={`sms:${varSeparator}body=${encodedName}%0A${smsEncodedUrl}%0A${this.props.description}`}
              className="share-btn-sms"
              title={this.props.labels.share.sms}
            >
              <span className="fa fa-sms"></span>
            </a>
          )}

          {/* Email */}
          {/* beware of `mailto:` length limitation (that's why description is at the end, it can be truncated) */}
          <a
            onClick={() =>
              this.openLink(
                `mailto:?subject=${encodedName}&body=${encodedName}%0A${encodedUrl}%0A${this.props.description}`,
                target
              )
            }
            className="share-btn-mail"
            title={this.props.labels.share.mail}
          >
            <span className="fa fa-envelope-square"></span>
          </a>

          {/* Facebook */}
          {config.FACEBOOK_APP_ID && (
            <a
              onClick={() => this.shareOnFacebook}
              className="share-btn-facebook"
              title={this.props.labels.share.facebook}
            >
              <span className="fa fa-facebook-square"></span>
            </a>
          )}

          {/* LinkedIn */}
          {config.LINKEDIN_API_KEY && (
            <a
              onClick={() => this.shareOnLinkedIn}
              className="share-btn-linkedin"
              title={this.props.labels.share.linkedin}
            >
              <span className="fa fa-linkedin-square"></span>
            </a>
          )}

          {/* Twitter */}
          <a
            onClick={() =>
              this.openLink(
                `https://twitter.com/share?url=${encodedUrl}&text=${encodedName}&hashtags=`,
                '_system'
              )
            }
            rel="noopener noreferrer"
            className="share-btn-twitter"
            title={this.props.labels.share.twitter}
          >
            <span className="fab fa-twitter-square"></span>
          </a>
        </div>
      </Dialog>
    );
  }
}

ShareDialog.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(ShareDialog);
