import {
  DISABLE_FAVORITE_ICON,
  DIALOG_TYPEBAR_CLOSED_IF_COUNT_ABOVE,
} from 'data/config/listConfig';

import {
  ALL_FAVORITES_DELETED,
  HIDE_DATA_LIST_DIALOG,
  LANG_CHANGED,
  MAP_USER_LOCATED,
  MAP_USER_UNLOCATED,
  SHOW_DATA_LIST_DIALOG,
  TOGGLE_FAVORITE,
} from 'src/store/actionTypes';

import { get as getLabels } from 'src/core/Lang';

import { hasFeatureRoute } from 'src/core/mapFeatures';

import { langChanged, setIsFavoriteFalse, updateObject } from 'src/store/reducers/commons';

const getDefaultState = () => ({
  labels: getLabels(),
  isOpen: false,
  favorites: {},
  favIconDisabled: DISABLE_FAVORITE_ICON === true,
  closedIfCountAbove: DIALOG_TYPEBAR_CLOSED_IF_COUNT_ABOVE,
});

const _toggleUserIsLocated = (state, action, isUserLocated) => {
  return updateObject(state, {
    showGoToButton: hasFeatureRoute() === true && isUserLocated === true,
  });
};

const _showDataListDialog = (state, action) => {
  const totalItems = Object.keys(action.items)
    .map((dataType) => (Array.isArray(action.items[dataType]) ? action.items[dataType].length : 0))
    .reduce((total, current) => total + current);

  if (totalItems === 0) {
    // Skip displaying data list dialog
    // This happens when the user clicks on "ignored" data types, like gateways
    return state;
  }

  return updateObject(state, {
    isOpen: true,
    title: action.title,
    items: action.items,
    favorites: action.favorites,
    placeId: action.placeId,
    pageKey: action.pageKey,
  });
};

const _hideDataListDialog = (state, action) => {
  return updateObject(state, {
    isOpen: false,
  });
};

const _toggleFavorite = (state, action) => {
  // Handle action if:
  //  - dialog is displayed
  //  - favorites list has been updated
  //  - faved item datatype matches one of the datatypes displayed in the dialog
  if (
    state.isOpen &&
    action.favListUpdated === true &&
    Object.keys(state.items).indexOf(state.dataType)
  ) {
    return updateObject(state, { favorites: action.favorites });
  }
  return state;
};

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case ALL_FAVORITES_DELETED:
      return setIsFavoriteFalse(state);
    case HIDE_DATA_LIST_DIALOG:
      return _hideDataListDialog(state, action);
    case LANG_CHANGED:
      return langChanged(state, action);
    case MAP_USER_LOCATED:
      return _toggleUserIsLocated(state, action, true);
    case MAP_USER_UNLOCATED:
      return _toggleUserIsLocated(state, action, false);
    case SHOW_DATA_LIST_DIALOG:
      return _showDataListDialog(state, action);
    case TOGGLE_FAVORITE:
      return _toggleFavorite(state, action);

    default:
      return state;
  }
};
