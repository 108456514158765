import config from 'data/config/config';
import { DATA_TYPE_FAVORITE_POSITIONS } from 'data/config/dataConfig';
import { MAP_PAGE_KEY, FAVORITES_PAGE_KEY } from 'src/pages/pagesKeys';

import {
  STATUS as SYNCHRO_FAV_STATUS,
  codePersistence,
  isUnauthorizedMode,
  isSynchFavPeeringMode,
} from 'src/core/favorites/SynchronizedFavoritesService';

import {
  CONFIG_JSON_LOADED,
  POLL_CONFIG_LOADED,
  ALL_FAVORITES_DELETED,
  DATA_ASSETS_UPDATED,
  FETCH_FAVORITES,
  HAS_NAVIGATED,
  LANG_CHANGED,
  PROFILE_CHANGED,
  SET_FAVORITES_SYNCHRONIZATION_STATUS,
  TOGGLE_FAVORITE,
  TOGGLE_LOCATION_STATUS,
  TOGGLE_MENU,
  UPDATE_PAGE_STATE,
  SET_LOGIN_STATUS,
  SET_CODE_IDENTIFICATION,
  SET_TEMPORARY_CODE_IDENTIFICATION,
  SHOW_FAVORITES_CODE_DIALOG,
  HIDE_FAVORITES_CODE_DIALOG,
  SET_FAVORITES_CODE_SYNC_STEP,
  USER_DATA_UPDATED,
} from 'src/store/actionTypes';

import {
  configJsonLoaded,
  pollConfigLoaded,
  getPageCommonDefaultState,
  langChanged,
  togglePageAfterNavigation,
  profileChanged,
  setFavoritesSynchronizationStatus,
  toggleLocationStatus,
  toggleMenu,
  updateObject,
  updatePageState,
  userDataUpdated,
} from 'src/store/reducers/commons';

import STATUS from 'src/store/fetchStatuses';

function getDefaultState() {
  const isSynchroFavFeatureEnabled = config.SYNCHRONIZED_FAVORITES.FEATURE_ENABLED === true;
  const synchroFavMode = config.SYNCHRONIZED_FAVORITES.MODE ? config.SYNCHRONIZED_FAVORITES.MODE : 'peering';

  return {
    ...getPageCommonDefaultState(FAVORITES_PAGE_KEY),
    code: null,
    codeSyncStep: 'choice',
    isCodeDialogOpen: false,
    isSynchroFavFeatureEnabled,
    synchroFavBtnStatus:
    isSynchFavPeeringMode() && codePersistence.get()
        ? SYNCHRO_FAV_STATUS.ONGOING
        : SYNCHRO_FAV_STATUS.DISABLED,
    temporaryCode: null,
    synchroFavMode,
    // Add any additional default state property
  };
}

// Set `shouldFetch`: true so the page will refresh its content
const __setShouldFetchTrue = (state) => updateObject(state, { shouldFetch: true });

const _allFavoritesDeleted = (state) => __setShouldFetchTrue(state);

function _toggleFavorite(state, action) {
  if (action.dataType === DATA_TYPE_FAVORITE_POSITIONS && action.source !== MAP_PAGE_KEY) {
    // Keep a clean data flow concerning favorite positions:
    //   - app favorites are updated only by Mobigeo.Favorite API events
    return state;
  }

  return action.favListUpdated === true ? __setShouldFetchTrue(state) : state;
}

const _dataUpdated = (state, action) => __setShouldFetchTrue(state);

/**
 * Initial action for this page
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
const _fetchFavorites = (state, action) => {
  return updateObject(state, {
    favorites: action.favorites,
    data: action.data,
    isPending: action.status === STATUS.PENDING,
    shouldFetch: false,
  });
};

const _setLoginStatus = (state, action) => {
  if (state.isLoggedIn !== action.loggedIn) {
    return updateObject(state, { isLoggedIn: action.loggedIn });
  }
  return state;
};

const _displayFavoritesCodeDialog = (state, display) => {
  if (!display)
    return updateObject(state, {
      isCodeDialogOpen: display,
      codeSyncStep: 'choice',
      temporaryCode: null,
    });
  return updateObject(state, { isCodeDialogOpen: display, temporaryCode: null });
};

const _setCodeIdentification = (state, action) => updateObject(state, { code: action.code });

const _setTemporaryCodeIdentification = (state, action) =>
  updateObject(state, { temporaryCode: action.code });

const _setFavoritesCodeSyncStep = (state, action) =>
  updateObject(state, { codeSyncStep: action.step, temporaryCode: null });

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case CONFIG_JSON_LOADED:
      return configJsonLoaded(state);

    case ALL_FAVORITES_DELETED:
      return _allFavoritesDeleted(state);

    case DATA_ASSETS_UPDATED:
      return _dataUpdated(state, action);

    case FETCH_FAVORITES:
      return _fetchFavorites(state, action);

    case LANG_CHANGED:
      return langChanged(state, action);

    case POLL_CONFIG_LOADED:
      return pollConfigLoaded(state, action);

    case PROFILE_CHANGED:
      return profileChanged(state, action, FAVORITES_PAGE_KEY);

    case SET_FAVORITES_SYNCHRONIZATION_STATUS:
      return setFavoritesSynchronizationStatus(state, action);

    case SET_LOGIN_STATUS:
      return _setLoginStatus(state, action);

    case SET_CODE_IDENTIFICATION:
      return _setCodeIdentification(state, action);

    case SET_TEMPORARY_CODE_IDENTIFICATION:
      return _setTemporaryCodeIdentification(state, action);

    case TOGGLE_FAVORITE:
      return _toggleFavorite(state, action);

    case TOGGLE_LOCATION_STATUS:
      return toggleLocationStatus(state, action);

    case TOGGLE_MENU:
      return toggleMenu(state, action, FAVORITES_PAGE_KEY);

    case UPDATE_PAGE_STATE:
      return updatePageState(state, action, FAVORITES_PAGE_KEY);

    case SHOW_FAVORITES_CODE_DIALOG:
      return _displayFavoritesCodeDialog(state, true);

    case HIDE_FAVORITES_CODE_DIALOG:
      return _displayFavoritesCodeDialog(state, false);

    case SET_FAVORITES_CODE_SYNC_STEP:
      return _setFavoritesCodeSyncStep(state, action);

    case HAS_NAVIGATED:
      return togglePageAfterNavigation(state, FAVORITES_PAGE_KEY);

    case USER_DATA_UPDATED:
      return userDataUpdated(state, action);

    default:
      return state;
  }
};
