import _defineProperty from "/Users/amedini/Documents/mspot-printemps/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _sortConfig;

import { DATA_TYPE_BRANDS, DATA_TYPE_BRAND_CATEGORIES, DATA_TYPE_EVENTS, DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITORS, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_INFOS, DATA_TYPE_NEWPRODUCTS, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_RESTAURANTS, DATA_TYPE_MONUMENTS, DATA_TYPE_SERVICES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_SPEAKERS, DATA_TYPE_FAVORITE_POSITIONS } from './dataConfig';
import { sortStrings, simpleSort, sortByStringifiedHour } from 'src/core/util/sortUtil';
import { formatDayMonthLong, formatDayMonthVeryLong } from 'src/core/Lang';
import { sortGroupedItems } from 'src/core/data-and-assets/Db';
export var SORT_CONFIG_TYPE = {
  // Indicate that the configuration only returns the value used to sort:
  valueGetter: 'valueGetter',
  // Indicate that sort must be performed with a specific configured function:
  sortFunction: 'sortFunction'
};
/**
 * Define the order of items.
 *
 * VALUE CAN BE:
 *  - THE DATA INDEX ON WHICH TO SORT ON
 *  - OR A FUNCTION TO APPLY
 */

var sortConfig = (_sortConfig = {}, _defineProperty(_sortConfig, DATA_TYPE_BRANDS, {
  valueGetter: function valueGetter(item) {
    return item.title;
  },
  valueType: 'string'
}), _defineProperty(_sortConfig, DATA_TYPE_EVENTS, {
  sortFunction: function sortFunction(evtA, evtB) {
    // sort by start_date (number)
    var sortResult = simpleSort(evtA.start_date, evtB.start_date);

    if (sortResult !== 0) {
      return sortResult;
    } else {
      var evtAstartTime = evtA.start_time || '00h00',
          evtBstartTime = evtB.start_time || '00h00'; // if start_date are equal, sort by hour (if one of them if missing, sort by name)

      sortResult = sortByStringifiedHour(evtAstartTime, evtBstartTime);

      if (sortResult !== 0) {
        return sortResult;
      } else {
        // if date and hour are the same, then sort by title
        return sortStrings(evtA.title, evtB.title);
      }
    }
  }
}), _defineProperty(_sortConfig, DATA_TYPE_EXHIBITORS, {
  valueGetter: function valueGetter(item) {
    return item.title;
  },
  valueType: 'string'
}), _defineProperty(_sortConfig, DATA_TYPE_INFOS, {
  valueGetter: function valueGetter(item) {
    return item.id;
  },
  valueType: 'number'
}), _defineProperty(_sortConfig, DATA_TYPE_NEWPRODUCTS, {
  valueGetter: function valueGetter(item) {
    return item.title;
  },
  valueType: 'string'
}), _defineProperty(_sortConfig, DATA_TYPE_RESTAURANTS, {
  valueGetter: function valueGetter(item) {
    return item.title;
  },
  valueType: 'string'
}), _defineProperty(_sortConfig, DATA_TYPE_MONUMENTS, {
  sortFunction: function sortFunction(itemA, itemB) {
    if (itemA.lump.rank !== undefined && itemB.lump.rank !== undefined && itemA.lump.rank !== null && itemB.lump.rank !== null) {
      return simpleSort(itemA.lump.rank, itemB.lump.rank);
    } else {
      return simpleSort(itemA.title, itemB.title);
    }
  }
}), _defineProperty(_sortConfig, DATA_TYPE_FAVORITE_POSITIONS, {
  valueGetter: function valueGetter(item) {
    return item.title;
  },
  valueType: 'string'
}), _defineProperty(_sortConfig, DATA_TYPE_SERVICES, {
  sortFunction: function sortFunction(itemA, itemB) {
    if (itemA.lump.rank !== undefined && itemB.lump.rank !== undefined && itemA.lump.rank !== null && itemB.lump.rank !== null) {
      return simpleSort(itemA.lump.rank, itemB.lump.rank);
    } else {
      return simpleSort(itemA.title, itemB.title);
    }
  }
}), _defineProperty(_sortConfig, DATA_TYPE_SPEAKERS, {
  valueGetter: function valueGetter(item) {
    return item.lastname;
  },
  valueType: 'string'
}), _defineProperty(_sortConfig, DATA_TYPE_BRAND_CATEGORIES, {
  sortFunction: categorySortFunction
}), _defineProperty(_sortConfig, DATA_TYPE_EVENT_CATEGORIES, {
  sortFunction: categorySortFunction
}), _defineProperty(_sortConfig, DATA_TYPE_EXHIBITOR_CATEGORIES, {
  sortFunction: categorySortFunction
}), _defineProperty(_sortConfig, DATA_TYPE_NEWPRODUCT_CATEGORIES, {
  sortFunction: categorySortFunction
}), _defineProperty(_sortConfig, DATA_TYPE_SERVICE_CATEGORIES, {
  sortFunction: categorySortFunction
}), _sortConfig);
export var getSortConfig = function getSortConfig() {
  return sortConfig;
};
/**
 * Define sort to apply when displaying data in DataListDialog component
 * @return {object}
 */

var dataListDialogSortConfig = {// Several possiblities:
  //   1) undefined/null : no sort is applied
  //   2) use what's defined for sortConfig to apply the exact same sort (e.g [DATA_TYPE_EVENTS]: sortConfig[DATA_TYPE_EVENTS],)
  //   3) define a specific sort (field or function. same format as sortConfig)
  // keep as example, but useless as brands are already properly sorted by MobiGeo when sending 'tap' event

  /*[DATA_TYPE_BRANDS]: {
      sortFunction: function(brandA, brandB) {
           // Sort by `lump.top_brand` then `title`
          let sortByTopBrandValue = simpleSort(brandB.lump.top_brand, brandA.lump.top_brand);
          return sortByTopBrandValue !== 0 ? sortByTopBrandValue : sortStrings(brandA.title, brandB.title);
      },
  },*/
  // [DATA_TYPE_EVENTS]: getSortConfig()[DATA_TYPE_EVENTS],
};
export var getDataListDialogSortConfig = function getDataListDialogSortConfig() {
  return dataListDialogSortConfig;
};
/**
 * Sort config for LIST GROUPS PAGE
 *
 * NB: If no config set for a datatype, then the default sort config if used
 */

var groupedItemsSortConfig = {};
export var getGroupedItemsSortConfig = function getGroupedItemsSortConfig() {
  return groupedItemsSortConfig;
};
/**
 * Generic sort for categories
 * @param  {object} catA
 * @param  {object} catB
 * @return {number}
 */

function categorySortFunction(catA, catB) {
  // 'all' category first
  if (catA.is_all === 1) {
    return -1;
  } else if (catB.is_all === 1) {
    return 1;
  } // by 'rank' if set


  if (catA.lump) {
    var rankA = catA.lump.rank;

    if (typeof catA.lump.rank === 'number') {
      var rankB = catB.lump.rank;

      if (rankA > rankB) {
        return 1;
      } else if (rankA < rankB) {
        return -1;
      }
    }
  } // by 'title'


  return sortStrings(catA.title, catB.title);
}

;
/**
 * Sort speakers by rank (indicate moderators) then title
 * @param  {array} speakers
 * @return {array}
 */

export function sortSpeakersByRankThenLastname(speakers) {
  return speakers.sort(function (sp1, sp2) {
    var sp1Rank = sp1.lump.rank,
        sp2Rank = sp2.lump.rank;

    if (sp1Rank === sp2Rank) {
      return sortStrings(sp1.lastname, sp2.lastname);
    } else {
      return simpleSort(sp1Rank, sp2Rank);
    }
  });
}
/**
 * Sort newProducts by rank, then title
 * @param  {array} newproducts
 * @return {array}
 */

export function sortNewProducts(newproducts) {
  return newproducts.sort(function (np1, np2) {
    var np1Rank = np1.lump.rank,
        np2Rank = np2.lump.rank;

    if (np1Rank === np2Rank) {
      return sortStrings(np1.title, np2.title);
    } else {
      return simpleSort(np1Rank, np2Rank);
    }
  });
}
export var formatGroupedItemDate = function formatGroupedItemDate(date) {
  return formatDayMonthVeryLong(date);
};
/**
 * @param  {array} items
 * @param  {string} dataType
 * @return {object}
 */

export function groupItems(items, dataType) {
  if (!items || items.length === 0) {
    console.warn('Cannot sort empty array', items);
    return;
  }

  var groupedItems = {};

  switch (dataType) {
    case DATA_TYPE_EVENTS:
      // Group events by day
      items.forEach(function (event) {
        if (event) {
          var date = event.start_date;

          if (typeof groupedItems[date] === 'undefined') {
            groupedItems[date] = {
              name: formatGroupedItemDate(date),
              subtitle: event.lump.day_title,
              sideIndexLabel: formatDayMonthLong(date),
              items: []
            };
          }

          groupedItems[date].items.push(event);
        }
      });
      break;

    case DATA_TYPE_EXHIBITORS:
      // Group exhibitors by lump.group
      items.forEach(function (exhibitor) {
        // "group": {"id":3, "name":"PLATINUM", "bgcolor":"#999999", "color":"#ffffff", "rank":1, "order":"title"}
        var group = exhibitor.lump.group;

        if (!group) {
          return;
        }

        if (typeof groupedItems[group.id] === 'undefined') {
          groupedItems[group.id] = {
            name: group.name,
            rank: group.rank,
            style: {
              color: group.color,
              backgroundColor: group.bgcolor
            },
            items: []
          };
        }

        groupedItems[group.id].items.push(exhibitor);
      });
      break;

    default:
      console.error('Cannot group items because there is no configuration for type: ' + dataType);
      return;
  } // Sort items in every group


  Object.keys(groupedItems).forEach(function (groupKey) {
    groupedItems[groupKey].items = sortGroupedItems(groupedItems[groupKey].items, dataType);
  });
  return groupedItems;
}
;