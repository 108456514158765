import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import { get as getLabels } from 'src/core/Lang';
import { getBindedActions } from 'src/store/bindedActions';
import { getCodePushDeploymentKey, getCodePushDeploymentVersion } from 'src/core/util/browser';

const SyncStatus = {
  UP_TO_DATE: 0,
  UPDATE_INSTALLED: 1,
  UPDATE_IGNORED: 2,
  UNKNOWN_ERROR: 3,
  SYNC_IN_PROGRESS: 4,
  CHECKING_FOR_UPDATE: 5,
  AWAITING_USER_ACTION: 6,
  DOWNLOADING_PACKAGE: 7,
  INSTALLING_UPDATE: 8,
};
const InstallMode = {
  IMMEDIATE: 0, // The update will be applied to the running application immediately. The application will be reloaded with the new content immediately.
  ON_NEXT_RESTART: 1, // The update is downloaded but not installed immediately. The new content will be available the next time the application is started.
  ON_NEXT_RESUME: 2, // The update is downloaded but not installed immediately. The new content will be available the next time the application is resumed or restarted, whichever event happens first.
  ON_NEXT_SUSPEND: 3,
};
const LOCALSTORAGE_KEY = 'codePush';
const LOG_PREF = '[codePush] ';

/**
 * Sub-mobule responsible for persistence
 * @type {Object}
 */
const persistence = {
  get() {
    const value = localStorage.getItem(LOCALSTORAGE_KEY);
    return value ? JSON.parse(value) : null;
  },
  set(value) {
    localStorage.setItem(
      LOCALSTORAGE_KEY,
      typeof value === 'string' ? JSON.stringify(value) : value
    );
  },
};

/**
 * Persist Code Push Deployment Version
 * @param {String} version
 */
export function setCodePushDeploymentVersion(version) {
  // console.log('setCodePushDeploymentVersion' + version);
  let tmpVersion;
  // Initialize if needed
  if (!version) {
    console.error(`${LOG_PREF} Empty version argument`);
  }
  tmpVersion = version.split('v')[1];
  if (tmpVersion && tmpVersion !== getDeploymentVersionCodePush()) persistence.set(version);
}

export function getDeploymentVersionCodePush() {
  let codePushDeployVersion = getCodePushDeploymentVersion() || '';
  if (persistence.get()) codePushDeployVersion = persistence.get();
  return codePushDeployVersion;
}

export function codePushInit() {
  codePushSync();
  //codePushCheckForUpdate();
  //codePushGetCurrentPackage();
}

export function codePushSync() {
  // console.log('codePushSync getCodePushDeploymentVersion:' + getCodePushDeploymentVersion());
  codePush.sync(
    (status) => notify(status),
    {
      // updateDialog: true,
      // minimumBackgroundDuration: 120,
      installMode: InstallMode.IMMEDIATE,
      mandatoryInstallMode: InstallMode.ON_NEXT_RESTART,
      // deploymentKey: getCodePushDeploymentKey(),
    },
    onDownloadProgress,
    (err) => onError('codePushSync:', err)
  );
}

function notify(val) {
  if (val === SyncStatus.DOWNLOADING_PACKAGE) {
    getBindedActions().showNotification({
      message: getLabels().update.modalTitle,
      level: NotificationLevels.INFO,
      duration: 7.4,
    });
  } else if (val === SyncStatus.INSTALLING_UPDATE) {
    getBindedActions().showNotification({
      message: getLabels().update.loading,
      level: NotificationLevels.INFO,
      duration: 3.7,
    });
  } else if (val === SyncStatus.UPDATE_INSTALLED) {
    getBindedActions().showNotification({
      message: getLabels().update.update_done,
      level: NotificationLevels.SUCCESS,
    });
  } else if (val === SyncStatus.UNKNOWN_ERROR) {
    getBindedActions().showNotification({
      message: getLabels().update.error,
      level: NotificationLevels.ERROR,
    });
  } else if (val === SyncStatus.AWAITING_USER_ACTION) {
    getBindedActions().showNotification({
      message: getLabels().update.new_version_detected,
      level: NotificationLevels.INFO,
    });
  }
}

var onDownloadProgress = function(downloadProgress) {
  if (downloadProgress) {
    alert('downloadProgress' + JSON.stringify(downloadProgress));
    // Update "downloading" modal with current download %
    // console.log('Downloading ' + downloadProgress.receivedBytes + ' of ' + downloadProgress.totalBytes);
  }
};

export function codePushCheckForUpdate() {
  // console.log('codePushCheckForUpdate');
  window.codePush.checkForUpdate(onUpdateCheck, (err) => onError('codePushCheckForUpdate:', err));
}

var onError = function(provider, error) {
  console.error(`${LOG_PREF} ` + provider + ': An error occurred. ' + error);
};

var onInstallSuccess = function() {
  // console.log('Installation succeeded.');
};

var onPackageDownloaded = function(localPackage) {
  // console.log('codePushCheckForUpdate onPackageDownloaded' + JSON.stringify(localPackage));
  // you can now update your application to the downloaded version by calling localPackage.install()
  localPackage.install(
    onInstallSuccess,
    (err) => onError('codePushCheckForUpdate onUpdateCheck: onPackageDownloaded:', err),
    null
    // {
    //   installMode: InstallMode.IMMEDIATE,
    //   mandatoryInstallMode: InstallMode.ON_NEXT_RESTART,
    //   deploymentKey: getCodePushDeploymentKey(),
    // }
  );
};

var onUpdateCheck = function(remotePackage) {
  if (!remotePackage) {
    // console.log('codePushCheckForUpdate The application is up to date.');
  } else {
    // console.log('codePushCheckForUpdate remotePackage: ' + JSON.stringify(remotePackage));
    if (remotePackage.label) setCodePushDeploymentVersion(remotePackage.label);
    // The hash of each previously reverted package is stored for later use.
    // This way, we avoid going into an infinite bad update/revert loop.
    if (!remotePackage.failedInstall) {
      // console.log('codePushCheckForUpdate A CodePush update is available. Package hash: ' +remotePackage.packageHash);
      remotePackage.download(
        onPackageDownloaded,
        (err) => onError('codePushCheckForUpdate onUpdateCheck:', err),
        onDownloadProgress
      );
    } else {
      // console.log('codePushCheckForUpdate The available update was attempted before and failed.');
    }
  }
};

export function codePushGetCurrentPackage() {
  // console.log('GetCurrentPackage');
  codePush.getCurrentPackage(function(update) {
    if (!update) {
      // console.log('GetCurrentPackage No updates have been installed');
      return;
    }
    // console.log('GetCurrentPackage update installed: ' + JSON.stringify(update));
    if (update.label) setCodePushDeploymentVersion(update.label);
    // If the current app "session" represents the first time
    // this update has run, and it had a description provided
    // with it upon release, let's show it to the end user
    if (update.isFirstRun && update.description) {
      // Display a "what's new?" modal
    }
  });
}
