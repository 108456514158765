import config from 'data/config/config';
import {
  DATA_TYPE_COUNTRIES,
  DATA_TYPE_EXHIBITORS,
  DATA_TYPE_EVENTS,
  DATA_TYPE_EVENT_CATEGORIES,
  DATA_TYPE_PLACES,
  DATA_TYPE_SPEAKERS,
  DATA_TYPE_DOCUMENTS,
  DATA_TYPE_DOCUNITS,
  DATA_TYPE_GMAP_PLACES,
  getParticipantExhibitorOriginalId,
} from 'data/config/dataConfig';
import { CATEGORIES_CLASSIFICATION } from 'data/config/ficheConfig';

import * as Db from 'src/core/data-and-assets/Db';

export const CATEGORIES_CLASSIFICATIONS = {
  CATEGORY_SYSTEM: 'CATSYS',
  TOP_CATS: 'TOPCATS',
};

const LOG_PREF = '[Query] ';

/**
 * @param  {object} item
 * @param  {string} dataType
 * @param  {array} relatedData (e.g ['places', 'newproducts', 'country'])
 * @return {object}
 */
export function completeData(item, dataType, relatedData) {
  const references = {};

  relatedData.forEach((dataName) => {
    switch (dataName) {
      case 'places':
        references.places = [];
        if (Array.isArray(item.places)) {
          item.places.forEach((placeData) => {
            if (placeData.place_id) {
              references.places.push({
                ...placeData, // sometimes placeData has an additional info field that needs to be included
                ...get(placeData.place_id, DATA_TYPE_PLACES),
              });
            }
          });
        }
        break;

      case 'placeLabel':
        references.placeLabel = getPlaceLabel(item.places); // warning item.lump.places -> item.places migration
        break;

      case 'country':
        if (item.country_id) {
          references.country = get(item.country_id, DATA_TYPE_COUNTRIES);
        }
        break;

      case 'cats':
        if (item.lump && Array.isArray(item.lump.cats)) {
          const categoryDataType = Db.getCategoryDatatype(dataType);

          // NB: lump.cats = direct parent categories
          references.categories = item.lump.cats
            .map((id) => get(id, categoryDataType))
            .filter((cat) => !!cat); // filter not found categories

          if (dataType === DATA_TYPE_EXHIBITORS) {
            references.catHierarchy = buildCategoryHierarchy(item, dataType, categoryDataType);
          }
        }
        break;

      case 'exhibitor':
        if (item.exhibitor_id) {
          references.exhibitor = get(item.exhibitor_id, DATA_TYPE_EXHIBITORS);
        } else if (typeof item.lump.exhibitors === 'number') {
          references.exhibitor = get(item.lump.exhibitors, DATA_TYPE_EXHIBITORS);
        }
        break;

      case 'moderators':
        if (Array.isArray(item.lump.moderators)) {
          references.moderators = item.lump.moderators.map((moderatorId) =>
            get(moderatorId, DATA_TYPE_SPEAKERS)
          );
        }
        break;

      case 'presidents':
        if (Array.isArray(item.lump.presidents)) {
          references.presidents = item.lump.presidents.map((presidentId) =>
            get(presidentId, DATA_TYPE_SPEAKERS)
          );
        }
        break;

      case 'directors':
        if (Array.isArray(item.lump.directors)) {
          references.directors = item.lump.directors.map((directorId) =>
            get(directorId, DATA_TYPE_SPEAKERS)
          );
        }
        break;

      case 'orators':
        if (Array.isArray(item.lump.orators)) {
          references.orators = item.lump.orators.map((oratorId) =>
            get(oratorId, DATA_TYPE_SPEAKERS)
          );
        }
        break;

      case 'coordinators':
        if (Array.isArray(item.lump.coordinators)) {
          references.coordinators = item.lump.coordinators.map((coordinatorId) =>
            get(coordinatorId, DATA_TYPE_SPEAKERS)
          );
        }
        break;

      case 'experts':
        if (Array.isArray(item.lump.experts)) {
          references.experts = item.lump.experts.map((expertId) =>
            get(expertId, DATA_TYPE_SPEAKERS)
          );
        }
        break;

      case 'coautors':
        if (Array.isArray(item.lump.coautors)) {
          references.coautors = item.lump.coautors.map((coautorId) =>
            get(coautorId, DATA_TYPE_SPEAKERS)
          );
        }
        break;

      case 'gmapplaces':
        references.gmapplaces = [];
        if (Array.isArray(item.lump.gmapplaces)) {
          item.lump.gmapplaces.forEach((placeData) => {
            if (placeData.place_id) {
              references.gmapplaces.push({
                ...placeData, // sometimes placeData has an additional info field that needs to be included
                ...get(placeData.place_id, DATA_TYPE_GMAP_PLACES),
              });
            }
          });
        } else if (item.lump.gmapplaces) {
          references.gmapplaces.push({
            ...get(item.lump.gmapplaces, DATA_TYPE_GMAP_PLACES),
          });
        }
        break;

      case 'documents':
        if (Array.isArray(item.lump.documents)) {
          references.documents = item.lump.documents.map((documentId) =>
            get(documentId, DATA_TYPE_DOCUMENTS)
          );
        }
        break;

      case 'docunits':
        if (Array.isArray(item.lump.docunits)) {
          references.docunits = item.lump.docunits.map((docunitId) =>
            get(docunitId, DATA_TYPE_DOCUNITS)
          );
        }
        break;

      case 'eventType':
        if (item.lump.Type) {
          references.eventType = get(item.lump.Type, DATA_TYPE_EVENT_CATEGORIES);
        }
        break;

      case 'eventTheme':
        if (item.lump.Theme) {
          references.eventTheme = get(item.lump.Theme, DATA_TYPE_EVENT_CATEGORIES);
        }
        break;

      case 'exhibitors':
        if (Array.isArray(item.lump.exhibitors)) {
          references.exhibitors = item.lump.exhibitors.map((exhibitor) => ({
            ...exhibitor,
            ...get(exhibitor.id ? exhibitor.id : exhibitor, DATA_TYPE_EXHIBITORS, ['places']),
          }));
        }
        break;

      case 'events':
        if (Array.isArray(item.lump.events)) {
          references.events = item.lump.events.map((event) => {
            if (event && event.id) {
              return {
                ...event,
                ...get(event.id, DATA_TYPE_EVENTS, ['places']),
              };
            }
            return {
              ...event,
              ...get(event, DATA_TYPE_EVENTS, ['places']),
            };
          });
        }
        break;

      case 'parent':
        if (item.lump.parent && item.lump.parent.id && item.lump.parent.type) {
          references.parent = get(item.lump.parent.id, item.lump.parent.type);
        }
        break;

      case 'participantDbEvents':
        // Get events from db
        if (!Array.isArray(item.events)) {
          references.events = [];
        } else {
          references.events = item.events.map((taigaEvent) =>
            find(
              [(e) => e.original_id === taigaEvent.id], // criterias
              DATA_TYPE_EVENTS,
              null, // related data to set
              true // find one
            )
          );
          // Filter void entries (not found events)
          references.events = references.events.filter((e) => e);
        }

        // Cross-check events (taiga/db)
        item.events.forEach((taigaEvent) => {
          taigaEvent.isTaigaEvent = true;

          if (Array.isArray(references.events)) {
            const dbEvent = references.events.find(
              (dbEvent) => dbEvent.original_id === taigaEvent.id
            );
            if (dbEvent) {
              taigaEvent.dbId = dbEvent.id;
            }
          }
        });
        break;

      case 'participantExhibitor':
        if (item) {
          let exhibitorId = getParticipantExhibitorOriginalId(item);
          if (exhibitorId) {
            references.exhibitor = findOne(
              [(exh) => exh.original_id === exhibitorId],
              DATA_TYPE_EXHIBITORS
            );
          }
        }
        break;

      default:
        // default: expect to find `item.lump[dataType]`
        if (Array.isArray(item.lump[dataName])) {
          references[dataName] = item.lump[dataName].map((item) => get(item, dataName));
        } /* else {
                    console.error(LOG_PREF+'Unexpected related data: '+dataName);
                } */
    }
  });

  return {
    ...item,
    references,
  };
}

function buildCategoryHierarchy(item, itemDataType, categoryDataType) {
  if (!item.lump || !Array.isArray(item.lump.parentCats)) {
    return [];
  }

  // NB: lump.parentCats = direct parent categories + grandparents + great-grandparents + etc
  const parentCategories = item.lump.parentCats.map((id) => get(id, categoryDataType));

  // Set the function used to filter level 1 categories (IN-56)
  let categoryClassificationFilter;
  switch (CATEGORIES_CLASSIFICATION) {
    case CATEGORIES_CLASSIFICATIONS.CATEGORY_SYSTEM:
      categoryClassificationFilter = isSystemCategory;
      break;

    case CATEGORIES_CLASSIFICATIONS.TOP_CATS:
      categoryClassificationFilter = isTopCategory;
      break;

    default:
      console.error(`${LOG_PREF}Cannot determine category hierarchy type`);
      return [];
  }

  const catsLevel1 = parentCategories.filter(categoryClassificationFilter);

  const remainingParents = parentCategories.filter((cat) => !categoryClassificationFilter(cat));
  const remainingParentsIds = remainingParents.map((cat) => cat.id);

  const matchSubCats = (cats) => cats.filter((catId) => remainingParentsIds.indexOf(catId) !== -1);

  const transformCat = (cat) => ({
    id: cat.id,
    type: categoryDataType,
    title: cat.title,
    counter: cat.counter,
    rank: cat.lump ? cat.lump.rank : null,
    [itemDataType]: cat.lump && cat.lump[itemDataType] ? cat.lump[itemDataType] : null,
    cats:
      !cat.lump || !Array.isArray(cat.lump.cats)
        ? null
        : Db.sortItems(
            matchSubCats(cat.lump.cats)
              .map((catId) => get(catId, categoryDataType))
              .filter((cat) => cat), // safety net: remove not found items
            categoryDataType
          ).map(transformCat),
  });

  return Db.sortItems(catsLevel1, categoryDataType).map(transformCat);
}

/**
 * Get label of the first placeId
 * @param  {array of numbers} placeIds
 * @return {string}
 */
function getPlaceLabel(placeIds) {
  if (Array.isArray(placeIds) && placeIds.length > 0) {
    let labels = [];
    placeIds.forEach((placeId) => {
      if (typeof placeId === 'number') {
        const place = get(placeId, DATA_TYPE_PLACES);

        // Skip places corresponding to synoptic view
        if (place && place.tag.startsWith('Syno') === false) {
          labels.push(place.label);
        }
      }
    });

    // Filter out empty or duplicated labels
    labels = labels.reduce(function(newArray, label) {
      if (label && newArray.indexOf(label) === -1) {
        newArray.push(label);
      }
      return newArray;
    }, []);

    return labels.sort().join(', ');
  }
  // default: undefined is returned
}

/**
 * Get by id
 * @param {number} id
 * @param {string} dataType  (@see VALID_DATA_TYPES)
 * @param {array} relatedDataToSet : related data to retrieve
 */
export const get = (id, dataType, relatedDataToSet) => {
  if (id === null || typeof id === 'undefined') {
    console.error(`${LOG_PREF}Missing \`id\` argument`);
    return;
  }
  return find([(item) => item.id === id], dataType, relatedDataToSet, true);
};
if (config.ENV === 'dev') {
  global.queryGet = get;
}

/**
 * Get all items of a data type
 * @param  {string} dataType
 * @return {array}
 */
export function getAll(dataType) {
  return Db.getSortedAndTransformedData()[dataType];
}
if (config.ENV === 'dev') {
  global.queryGetAll = getAll;
}

/**
 * Get original id from id
 * @param  {string} originalId
 * @param  {string} dataType
 * @return {number}
 */
export function getIdFromOriginalId(originalId, dataType) {
  const item = find([(item) => item.original_id === originalId], dataType, null, true);

  if (item) {
    return item.id;
  }
  console.error(
    `${LOG_PREF}Could not find item of type '${dataType}' matching original_id value: ${originalId}`
  );
}

/**
 * @param  {array} criterias: ARRAY of **FUNCTIONS**
 * @param  {string} dataType
 * @param  {array} relatedDataToSet
 * @param  {boolean} findOne  stop when first occurence is found
 * @return {array}
 */
export const find = (criterias, dataType, relatedDataToSet, findOne) => {
  if (!dataType) {
    console.error(`${LOG_PREF}Missing \`dataType\` argument`, dataType);
    return;
  }
  if (typeof Db.getSortedAndTransformedData()[dataType] === 'undefined') {
    // data may not have been fetched yet (e.g provided by a web service)
    console.warn(`${LOG_PREF}Missing data for `, dataType);
    return;
  }
  if (!criterias || Array.isArray(criterias) !== true || criterias.length === 0) {
    console.error(`${LOG_PREF}Missing criterias to look for ${dataType} items.`);
    return;
  }

  const data = getAll(dataType);

  // Apply criteria functions. Return true is all criterias match.
  function applyCriterias(item) {
    let match = true;

    for (let i = 0; i < criterias.length && match; i++) {
      match = match && criterias[i](item);
    }
    return match;
  }

  let matches;
  if (findOne) {
    const match = data.find(applyCriterias);
    matches = match ? [match] : [];
  } else {
    matches = data.filter(applyCriterias);
  }

  // Fetch related data
  if (Array.isArray(relatedDataToSet)) {
    matches = matches.map((item) => completeData(item, dataType, relatedDataToSet));
  }

  if (findOne) {
    return matches.length > 0 ? matches[0] : null;
  }

  return matches;
};
if (config.ENV === 'dev') {
  global.queryFind = find;
}

export const findOne = (criterias, dataType, relatedDataToSet) =>
  find(criterias, dataType, relatedDataToSet, true);

const isTopCategory = (cat) => cat.parent_id === null;
export const getTopCategories = (dataType) => find([isTopCategory], dataType);

const isSystemCategory = (cat) => cat.lump && cat.lump.category_system === true;
export const getSystemCategories = (dataType) => find([isSystemCategory], dataType);

const getCategoriesHierarchy_timeCode = 'getCategoriesHierarchy';
export function getCategoriesHierarchy(catId, catDataType) {
  console.time(getCategoriesHierarchy_timeCode);

  const cat = _getCategoriesHierarchy(catId, catDataType);

  console.timeEnd(getCategoriesHierarchy_timeCode);
  return cat;
}

// Wrapped only for time computation
function _getCategoriesHierarchy(catId, catDataType) {
  const cat = get(catId, catDataType);
  cat.cats = Db.sortItems(
    (cat.lump.cats || []).map((subCatId) => _getCategoriesHierarchy(subCatId, catDataType)),
    catDataType
  );
  cat.parent_ids = getParentCategoriesId(cat, catDataType);
  cat.descendants_cat_ids = getDescendantsCategoriesId(cat, catDataType);
  return cat;
}

/**
 * Recursively get parent categories ids
 * @param  {object} cat
 * @param  {string} dataType
 * @return {array}
 */
export function getParentCategoriesId(cat, dataType) {
  if (!cat.parent_id) {
    return [];
  }
  const parentCat = get(cat.parent_id, dataType);
  if (!parentCat) {
    return [];
  }
  return [parentCat.id].concat(getParentCategoriesId(parentCat, dataType));
}

/**
 * Recursively get descendants categories ids
 * @param  {object} cat
 * @param  {string} dataType
 * @return {array}
 */
export function getDescendantsCategoriesId(cat, dataType) {
  let childrenCats = cat && cat.lump && Array.isArray(cat.lump.cats) ? cat.lump.cats : [];

  childrenCats.forEach((subCatId) => {
    childrenCats = childrenCats.concat(
      getDescendantsCategoriesId(get(subCatId, dataType), dataType)
    );
  });
  return childrenCats;
}
