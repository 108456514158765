import React from 'react';

import { getValueFromStringAttribute } from 'src/core/util/JsTools';

let isAllChecked = false;
export default function({
  fieldName,
  className,
  currentFormValue,
  setValue,
  label,
  labels,
  multiple,
  values,
  setFieldVisiblity,
  icon,
}) {
  const visible = !!values;
  if (typeof setFieldVisiblity === 'function') {
    setFieldVisiblity(fieldName, visible);
  }

  if (!visible) {
    return null;
  }
  if (currentFormValue.length === 0) {
    isAllChecked = false;
  }
  const setChecked = (checked) => {
    isAllChecked = checked;
  };
  const checkAll = (value) => {
    setChecked(!isAllChecked);
    setValue(value);
  };
  return (
    <div key={fieldName} className={`generic-field-row ${className || ''}`}>
      <div className="input-group input-group-checkbox">
        {icon && <i className={`${icon} text-color1`} />}
        {label && <label className="text-color1" dangerouslySetInnerHTML={{ __html: label }} />}
        <div className="generic-field-row-chkbox">
          {multiple && (
            <div
              onClick={() =>
                isAllChecked ? checkAll([]) : checkAll(Array.from(values, (value) => value.value))
              }
              className={isAllChecked ? 'generic-chkbox-row-checked' : ''}
            >
              <span className={isAllChecked ? 'fas fa-check-square' : 'far fa-square'} />
              <span className="generic-chkbx-label">{labels.searchTaiga.allCheckboxLabel}</span>
            </div>
          )}
          {values.map((checkBoxConf, index) =>
            renderCheckBox({
              index,
              checkBoxConf,
              multiple,
              currentFormValue,
              setValue,
              labels,
            })
          )}
        </div>
      </div>
    </div>
  );
}

function renderCheckBox({ index, checkBoxConf, multiple, currentFormValue, setValue, labels }) {
  const { value } = checkBoxConf;

  const isChecked = currentFormValue.indexOf(value) !== -1;

  function check() {
    if (multiple) {
      isAllChecked = true;
      setValue(currentFormValue.concat(value));
    } else {
      setValue(value);
    }
  }

  function uncheck() {
    if (multiple) {
      if (currentFormValue.length === 1) isAllChecked = false;
      setValue(currentFormValue.filter((_value) => _value !== value));
    } else {
      setValue('');
    }
  }

  function getCheckBoxClassName() {
    const className = ['generic-chkbx'];
    if (multiple) {
      if (isChecked) {
        className.push('fas', 'fa-check-square');
      } else {
        className.push('far', 'fa-square');
      }
    } else if (isChecked) {
      className.push('fas', 'fa-circle');
    } else {
      className.push('fal', 'fa-circle');
    }
    return className.join(' ');
  }

  return (
    <div
      key={index}
      onClick={isChecked ? uncheck : check}
      className={isChecked ? 'generic-chkbox-row-checked' : ''}
    >
      <span className={getCheckBoxClassName()} />
      <span className="generic-chkbx-label">
        {getValueFromStringAttribute(labels, checkBoxConf.label) || checkBoxConf.label}
      </span>
    </div>
  );
}
