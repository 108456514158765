import { createItem } from 'src/core/util/ConfigItemFactory';
import {
  isIOS,
  getBuildVersion,
  getCodePushDeploymentVersion,
  isWkWebview,
  isCordovaContext,
} from 'src/core/util/browser';

function noop() {}

export default function(labels) {
  return createItem(
    // style
    {
      container: {
        className: 'menu-entry-app-version',
      },
    },
    // label
    `v${process.env.APP_VERSION}.${getBuildVersion()}${
      isCordovaContext() && getCodePushDeploymentVersion() !== ''
        ? '.' + getCodePushDeploymentVersion()
        : ''
    }`,
    // action
    noop
  );
}
