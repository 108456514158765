module.exports = {
  name: "printemps",
  client: "Printemps Haussmann",
  description: "Projet MobiGeo pour l'application Printemps Haussmann",
  pluginId: "mobigeo-plugin-ph",
  validLangs: ["en", "fr", "zh"],
  version: {
    mainVersion: "3.9.0",
    buildVersion: {
      iOS: "55",
      android: "55",
      web: "55"
    }
  },
  native: {
    author: "Mobile-Spot",
    backgroundColor: "0xffffff",
    // iOS
    iosAppStoreUrl: "https://itunes.apple.com/app/printemps-haussmann/id853771459",
    iosBuildTarget: "10.0",
    // Android
    // 'androidapp-id': 'TODO', // unused?
    playStoreUrl: "https://play.google.com/store/apps/details?id=com.printemps.haussmann",
    androidFadeSplashScreenDuration: 750,
    // Web app to wrap in the cordova app ('ionic/www', 'simple', 'test', 'demo', 'app-react' - see cordova/testapps/)
    app: "app-react"
  },
  // List the folders of the data bundles to include for this project
  data: {
    default: "ahd5Ahqu2oof"
  },
  // 'location', 'location.geogroup', 'location.geopush', 'map.route', 'assets.update', 'poi.aroundme', 'favorites'
  features: ["location", "map.route", "favorites"],
  location: {
    type: ["nao"],
    // 'nao' | 'intus'
    key: "l55t8GXcOzwO_FDgNLiyYA",
    files: [{
      "5026.jscx": "assets/pdbs"
    }, {
      "app.json": "assets"
    }],
    registering: {
      location: true,
      geofencing: false
    },
    mapping: {},
    forceSynchronize: true
  },
  undeliveredFolders: ["source", "exports"],
  // which additional repos need to be cloned
  relatedRepos: [{
    src: "git@bitbucket.org:mobilespotdev/cordova.git",
    postCommands: ["cd cordova && npm install"],
    branch: "inte/printemps/main"
  }, {
    src: "git@bitbucket.org:mobilespotdev/mobigeo.git",
    postCommands: ["cd mobigeo && npm install"],
    branch: "inte/printemps/main"
  }, {
    src: "git@bitbucket.org:mobilespotdev/app-react.git",
    branch: "inte/printemps/main"
  }, {
    src: "git@bitbucket.org:mobilespotdev/data-printemps.git",
    dest: "app-react/data",
    branch: "inte/printemps/main",
    postCommands: ["cd app-react && yarn", "cd app-react && npm run set-env dev", "cd app-react && npm run download-assets", "cd app-react && npm run download-data-files", "cd app-react && npm run update-mobigeo"]
  }],
  cordova: true,
  crypto: false,
  // @see doc on google drive: R&D/Migration WK webview: https://docs.google.com/spreadsheets/d/1sIfm3LgBq1Zgp7LH2-mKSD1vrL6VphNR1mkiHZ73zHM/edit?usp=drive_web&ouid=101003723454836243359
  // @see cordova/bin/addIosCustomUrlScheme.js
  // @see app-react/src/core/data-and-assets/FileSystemHelper.js `getPath`
  // @see app-react/src/core/util/FetchHelper.js
  customFileUrlScheme: "msfile",
  cordova_plugins: {
    mobigeo_plugin_dependencies: [{
      id: "cordova-plugin-bluetooth-status"
    }],
    app_dependencies: [{
      id: "https://github.com/etienneMobileSpot/cordova-plugin-geolocation.git#permissionsTests"
    }, {
      id: "../src-app-plugins/cordova-plugin-mobilespot-app",
      variable: "HOST",
      varValue: "web.url"
    }, {
      id: "../src-app-plugins/cordova-plugin-mobilespot-badge-scan"
    }, {
      id: "cordova-plugin-file"
    }, {
      id: "cordova-plugin-file-opener2",
      variable: "ANDROID_SUPPORT_V4_VERSION",
      rawVarValue: "27.+"
    }]
  },
  geoGroup: {}
};