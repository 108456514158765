import * as Query from 'src/core/query/Query';


export const getItemByDataTypeFromDb = (dataType, property, value) => {
    const data = Query.getAll(dataType);
    if (data && data.length) {
      const result =
        typeof value === 'number'
          ? data.find((it) => it[property] === value)
          : data.find((it) => it[property].toLowerCase() === value.toLowerCase());
      return result;
    }
    return '';
  };
  
  export const getOriginalIdFromDb = (parentType, dataType, property, value, propertyToReturn) => {
    const data = getItemByDataTypeFromDb(parentType, property, value);
    const idServices = data && data.lump.services[0];
    if (idServices) {
      const result = getItemByDataTypeFromDb(dataType, 'id', idServices);
      return propertyToReturn ? result[propertyToReturn] : result;
    }
    return '';
  };
  