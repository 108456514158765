import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Conf
import { DATA_TYPE_MONUMENTS } from 'data/config/dataConfig';
import { MONUMENT_PAGE_KEY } from 'src/pages/pagesKeys';

import DetailPlaces from 'src/components/detail-places/DetailPlaces';
import DetailFixedTitle from 'src/components/detail-fixed-title/DetailFixedTitle';
import Description from 'src/components/fiche/Description';
import PhoneRow from 'src/components/fiche/PhoneRow';
import WebsiteRow from 'src/components/fiche/WebsiteRow';
import EmailRow from 'src/components/fiche/EmailRow';
import ShareButton from 'src/components/share-button/ShareButton';
import NoteButton from 'src/components/notes/NoteButton';

import '../common-fiche.scss';

class MonumentContent extends PureComponent {
  setScrollableContentEl = (el) => {
    this.contentContainerEl = el;
  };
  scrollTo = (value) => {
    if (this.contentContainerEl) {
      this.contentContainerEl.scrollTop = value;
    }
  };

  onClickOnFavIcon = () => {
    this.props.actions.toggleFavorite(
      this.props.item.id,
      DATA_TYPE_MONUMENTS,
      this.props.isFavorite
    );
  };

  render() {
    let lump = this.props.item.lump,
      hasPlaces =
        this.props.item.references &&
        Array.isArray(this.props.item.references.places) &&
        this.props.item.references.places.length > 0;

    const title = this.props.item.title,
      logoUrl = this.props.item.logo_file_name,
      description = this.props.item.description;

    return (
      <div className="fiche monument content-font content-below-apptoolbar">
        {/* title */}
        <DetailFixedTitle
          title={title}
          isFav={this.props.isFavorite}
          onFavClick={this.onClickOnFavIcon}
          labels={this.props.labels}
        >
          <NoteButton
            itemTitle={this.props.item.title}
            itemId={this.props.item.id}
            dataType={DATA_TYPE_MONUMENTS}
            hasNote={this.props.hasNote}
            labels={this.props.labels}
            actions={this.props.actions}
          />
          <ShareButton
            name={this.props.pageTitle}
            queryString={this.props.queryString}
            description={description}
            image={logoUrl}
            labels={this.props.labels}
            actions={this.props.actions}
            profile={this.props.profile}
            pageKey={MONUMENT_PAGE_KEY}
          />
        </DetailFixedTitle>

        <div className="content" ref={this.setScrollableContentEl}>
          <div className="all-informations content-font">
            <Description
              logoFileName={logoUrl}
              dataType={DATA_TYPE_MONUMENTS}
              description={description}
              isLogoFullWidth={this.props.isLogoFullWidth}
            />

            {hasPlaces && (
              <div>
                <DetailPlaces
                  labels={this.props.labels}
                  actions={this.props.actions}
                  id={this.props.item.id}
                  originalId={this.props.item.original_id}
                  entityType={DATA_TYPE_MONUMENTS}
                  places={lump.oneMapButton ? [] : this.props.item.references.places}
                />
              </div>
            )}

            {/* contacts bar */}
            {(this.props.item.website || this.props.item.email || this.props.item.phone) && (
              <div className="fiche-separator">{this.props.labels.common.contact}</div>
            )}

            <PhoneRow phone={this.props.item.phone} />

            <WebsiteRow website={this.props.item.website} actions={this.props.actions} />

            <EmailRow email={this.props.item.email} />
          </div>
        </div>
      </div>
    );
  }
}

MonumentContent.propTypes = {
  item: PropTypes.object,
  isFavorite: PropTypes.bool,
  isLogoFullWidth: PropTypes.bool,
  // Common
  queryString: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default MonumentContent;
