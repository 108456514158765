import { debounce } from 'lodash-custom';

import config from 'data/config/config';

import { MOBIGEO_USED_TABLES, DATA_TYPE_SERVICE_CATEGORIES } from 'data/config/dataConfig';

import { LIST_PAGE_KEY, MAP_PAGE_KEY } from 'src/pages/pagesKeys';

import SearchPlaceTypes from 'src/components-standalone/search-place-dialog/SearchPlaceTypes';
import { convertMobigeoType } from 'src/core/data-and-assets/Db';
import { get as getLabels } from 'src/core/Lang';
import showConfirmModal from 'src/core/util/showConfirmModal';
import { encodeProps } from 'src/core/navigation/History';
import { getBindedActions } from 'src/store/bindedActions';

import { StoredManualPMRStatus } from 'src/pages/pmr/PmrHelper';
import { StoredManualLocationStatus } from 'src/pages/map/locationHelper';

import { setStartPosition, setEndPosition, setPmr } from 'src/core/navigation/CurrentRoute';

import share from 'src/core/share';
import mapPageConfig from './config';
import { getLocation } from '../../core/navigation/History';

const LOG_PREF = '[mapUtil] ';

/**
 * Detect if assets update impacts on map
 * @param  {array} assets
 * @return {array}
 */
export const detectMapAssetUpdate = (assets) =>
  (assets || []).filter((path) => path.indexOf('files/maps/') !== -1);

/**
 * Detect if data update impacts on map
 * @param  {array} updated tables
 * @return {array}
 */
export function detectMapDataUpdate(tables) {
  if (!Array.isArray(tables)) {
    return [];
  }
  // 'all' corresponds with initial data loading on app startup
  if (tables.length === 1 && tables[0] === 'all') {
    return MOBIGEO_USED_TABLES;
  }
  return tables.filter((tableName) => MOBIGEO_USED_TABLES.indexOf(tableName) !== -1);
}

/**
 * What MobiGeo needs to display a POI is (@see MobiGeo API doc) :
 * {
 *   id: id,
 *   originalId: originalId,
 *   type: dataType,
 *   placeId: optional,
 * }
 *
 * Since the app should be able to display one/several POI from a single URL, only the needed information is transmitted.
 *  - cleaner url
 *  - avoid url length limit (which occured several times)
 *  - less data to process/parse
 *
 *
 * So this function converts array of objects to a lightweight version holding only the needed data.
 *
 * e.g, from:
 *  {
 *     exhibitors: array of full objects,
 *     newproducts: ...
 *     etc
 *  }
 * is returned:
 *  {
 *      exhibitors: array of objects such as { id: id, originalId: originalId }, `type` attribute is added by mapMiddleware
 *      newproducts: ...
 *      etc
 *  }
 *
 * @param  {object} data
 * @return {object}
 */
export function stripToIds(data) {
  const formatted = {};

  Object.keys(data).forEach((dataType) => {
    let items;
    if (Array.isArray(data[dataType])) {
      items = data[dataType];
    } else if (data[dataType].data && Array.isArray(data[dataType].data.all)) {
      items = data[dataType].data.all;
    }
    if (items) {
      formatted[dataType] = items.map((item) => ({
        id: item.id,
        originalId: item.original_id,
      }));
    }
  });

  return formatted;
}

export const USER_POSITION = 'user-position';

function commonMobiGeoErrorHandler(error, moduleName, args) {
  console.warn(`${LOG_PREF + moduleName} thrown error ${error}`, args);
  getBindedActions().mobigeoErrorThrown(error, moduleName, args);

  const message = getLabels().map.errors[error];
  if (message) {
    getBindedActions().showNotification({ message });
  }
}

export const convertEntryForMap = (entry) =>
  !entry
    ? null
    : {
        id: entry.originalId || entry.id,
        placeId: entry.placeId,
        type: entry.type,
      };

/**
 * @param {object} actions (store binded)
 */
export function addListeners() {
  // NB: MobiGeo.Map 'ready' event listener stayed in MapPage because it is closely related

  // Location events

  window.MobiGeo.Location.on('requestLocationCapabilities', () => {
    console.info(`${LOG_PREF}Location requestLocationCapabilities`);
    StoredManualLocationStatus.setEnabled();
    getBindedActions().requestLocationCapabilities();
  });

  window.MobiGeo.Location.on('locationUnavailable', () => {
    console.info(`${LOG_PREF}Location locationUnavailable`);
    getBindedActions().mapUserUnlocated();
  });

  window.MobiGeo.Location.on('locationAvailable', () => {
    console.info(`${LOG_PREF}Location locationAvailable`);
    getBindedActions().mapUserLocated();
  });

  window.MobiGeo.Location.on('enterSite', (name) => {
    console.info(`${LOG_PREF}Location enterSite: ${name}`);
    getBindedActions().mapUserLocated();
  });

  window.MobiGeo.Location.on('exitSite', (name) => {
    console.info(`${LOG_PREF}Location exitSite: ${name}`);
    getBindedActions().mapUserUnlocated();
  });

  window.MobiGeo.Location.on('started', () => {
    console.info(`${LOG_PREF}Location started`);
    getBindedActions().toggleLocationStatus(true);
  });

  window.MobiGeo.Location.on('stopped', () => {
    console.info(`${LOG_PREF}Location stopped`);
    getBindedActions().mapUserUnlocated();
    getBindedActions().toggleLocationStatus(false);
  });

  // POI tap
  window.MobiGeo.Map.POI.on(
    'tap',
    debounce((data) => {
      console.log(`${LOG_PREF}POI selected`, data);

      const placeId = data._id;
      const pois = {};
      data.POIs.forEach((poi) => {
        const type = convertMobigeoType(poi.type);
        if (!pois[type]) {
          pois[type] = [];
        }
        pois[type].push(poi._id);
      });
      getBindedActions().showDataListDialog(pois, placeId, MAP_PAGE_KEY);
    }, 100)
  );

  // Itinerary button tap
  window.MobiGeo.Map.on('tapButtonItinerary', () => {
    getBindedActions().showSearchPlaceDialog(SearchPlaceTypes.start);
  });

  // Search button tap
  window.MobiGeo.Map.on('tapButtonSearch', () => {
    getBindedActions().showSearchPlaceDialog(SearchPlaceTypes.poi);
  });

  // Services button tap
  window.MobiGeo.Map.on('tapButtonServices', () => {
    getBindedActions().navigate(LIST_PAGE_KEY, {
      inputs: [{ dataType: DATA_TYPE_SERVICE_CATEGORIES }],
    });
  });

  // Itinerary events

  // User has a position we need to check the PMR status, accordingly show PMR popup or not and then return PMR status to mobigeo
  window.MobiGeo.Map.Route.on('showPMRStatusPristineModal', (poi) => {
    function next() {
      getBindedActions().dispatchItinerary(poi);
    }

    const labels = getLabels();
    showConfirmModal({
      text: labels.menu.pmrQuestion,
      yesBtnLabel: labels.common.yes,
      noBtnLabel: labels.common.no,
      yesCb() {
        getBindedActions().togglePMRStatus(true);
        StoredManualPMRStatus.setEnabled();
        next();
      },
      noCb() {
        getBindedActions().togglePMRStatus(false);
        StoredManualPMRStatus.setDisabled();
        next();
      },
    });
  });

  // User has no position we launch start position form
  window.MobiGeo.Map.Route.on('destinationSelected', (poi) => {
    getBindedActions().showSearchPlaceDialog(SearchPlaceTypes.start);
    getBindedActions().searchedPlaceSelected(SearchPlaceTypes.dest, {
      id: typeof poi.id === 'string' ? parseInt(poi.id, 10) : poi.id,
      originalId: poi.originalId,
      type: convertMobigeoType(poi.type),
      placeId: poi.placeId,
      text: poi.text,
    });
  });

  // Favorite positions events
  window.MobiGeo.Favorite.on('favorite-added', (fav) => {
    // Trigger a refresh for favorite page
    getBindedActions().toggleFavorite(fav.id, fav.type, false, false, MAP_PAGE_KEY, fav);
    getBindedActions().mapFavoriteCreated();
  });
  window.MobiGeo.Favorite.on('favorite-removed', (fav) => {
    // Trigger a refresh for favorite page
    getBindedActions().toggleFavorite(fav.id, fav.type, true, false, MAP_PAGE_KEY);
  });
  if (config.SHARE && config.SHARE.FEATURE_ENABLED === true) {
    window.MobiGeo.Favorite.on('favorite-share', (fav) => {
      const labels = getLabels();

      const queryString =
        config.ROUTE_SEPARATOR +
        mapPageConfig.path +
        encodeProps({
          favorite: {
            title: fav.title,
            x: fav.places[0].x,
            y: fav.places[0].y,
            zone: fav.places[0].zone,
            floor: fav.places[0].floor,
          },
        });
      share(
        fav.title,
        queryString,
        labels.share.positionShare,
        config.SHARE.POSITION_SHARE_IMAGE,
        labels
      );

      getBindedActions().mapFavoriteShared();
    });
  }

  // Geogroup events
  window.MobiGeo.Location.GeoGroup.on('usernameSet', (user) => {
    getBindedActions().geogroupPseudoSet(user);
  });
  window.MobiGeo.Location.GeoGroup.on('groupCreated', (group) => {
    getBindedActions().geogroupGroupCreated(group);
  });
  window.MobiGeo.Location.GeoGroup.on('groupJoined', (group) => {
    getBindedActions().geogroupGroupJoined(group);
  });
  window.MobiGeo.Location.GeoGroup.on('groupExited', (group) => {
    getBindedActions().geogroupGroupQuitted(group);
  });

  // Error handlers
  window.MobiGeo.on('error', (err, ...args) => {
    commonMobiGeoErrorHandler(err, 'MobiGeo', args);
  });
  window.MobiGeo.Map.on('error', (err, ...args) => {
    commonMobiGeoErrorHandler(err, 'MobiGeo.Map', args);
  });
  window.MobiGeo.Map.Route.on('error', (err, ...args) => {
    commonMobiGeoErrorHandler(err, 'MobiGeo.Map.Route', args);
  });
  window.MobiGeo.Location.on('error', (err, ...args) => {
    commonMobiGeoErrorHandler(err, 'MobiGeo.Location', args);
  });
  window.MobiGeo.Location.GeoGroup.on('error', (err, ...args) => {
    commonMobiGeoErrorHandler(err, 'MobiGeo.Location.GeoGroup', args);
  });
  window.MobiGeo.Location.GeoPush.on('error', (err, ...args) => {
    commonMobiGeoErrorHandler(err, 'MobiGeo.Location.GeoPush', args);
  });
}
