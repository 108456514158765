import React from 'react';
import PropTypes from 'prop-types';

import CTAButton from 'src/components/cta-button/CTAButton';

function TextField({
  fieldName,
  className,
  currentFormValue,
  setValue,
  label,
  placeholder,
  type,
  performSearch,
  isSearchOngoing,
  hasSearchButton,
  setFieldVisiblity,
  autofocus,
  icon,
  displayLabel,
  prefilled,
}) {
  if (typeof setFieldVisiblity === 'function') {
    setFieldVisiblity(fieldName, true);
  }

  let setRef = null;
  if (autofocus) {
    setRef = function setRef(el) {
      if (autofocus && el) {
        el.focus();
        if (prefilled && prefilled.suffix && el.value === prefilled.suffix) {
          el.setAttribute('type', 'text');
          el.setSelectionRange(0, 0);
          el.setAttribute('type', type);
        }
      }
    };
  }

  return (
    <div key={fieldName} className={`generic-field-row ${className || ''}`}>
      {icon && <i className={`${icon} text-color1`} />}
      <div
        className={` ${hasSearchButton ? 'input-group' : 'input-simple'}${(displayLabel &&
          ' input-with-label') ||
          ''}`}
      >
        {displayLabel && label && <span className="text-color1 input-label">{label}</span>}
        <div className="input-search">
          <div className="input-with-clear">
            {type === 'textarea' ? (
              <textarea
                ref={setRef}
                value={currentFormValue}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                placeholder={placeholder || ''}
              />
            ) : (
              <input
                ref={setRef}
                type={type || 'text'}
                value={currentFormValue}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                placeholder={placeholder || ''}
              />
            )}
            {currentFormValue && (
              <span
                className="search-field-clear fal fa-times"
                onClick={() => {
                  setValue('');
                }}
              />
            )}
          </div>

          {hasSearchButton && (
            <CTAButton
              id="sfs-search-btn-small"
              label=""
              action={performSearch}
              isEnabled={!isSearchOngoing}
            >
              <div className="fas fa-search" />
            </CTAButton>
          )}
        </div>
      </div>
    </div>
  );
}

TextField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  currentFormValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func.isRequired,
  label: PropTypes.string,
  type: PropTypes.string, // common input types for text field (text, number, email, also handles textarea)
  setFieldVisiblity: PropTypes.func, // called on render
  // display from conf
  displayLabel: PropTypes.bool,
  icon: PropTypes.string,
  // Ability to have a search button (can easily be abstracted later)
  performSearch: PropTypes.func,
  isSearchOngoing: PropTypes.bool,
  hasSearchButton: PropTypes.bool,
};

export default TextField;
