import { HIDE_SHARE_DIALOG, LANG_CHANGED, SHOW_SHARE_DIALOG } from 'src/store/actionTypes';
import { langChanged, updateObject } from 'src/store/reducers/commons';

import { get as getLabels } from 'src/core/Lang';

const getDefaultState = () => ({
  labels: getLabels(),
  isOpen: false,
});

const _toggleDialog = (state, action, value) => {
  return updateObject(state, {
    isOpen: value,
    name: action.name,
    description: action.description,
    url: action.url,
    image: action.image,
  });
};

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case HIDE_SHARE_DIALOG:
      return _toggleDialog(state, action, false);
    case LANG_CHANGED:
      return langChanged(state, action);
    case SHOW_SHARE_DIALOG:
      return _toggleDialog(state, action, true);

    default:
      return state;
  }
};
