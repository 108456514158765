import React from 'react';
import PropTypes from 'prop-types';

import './Loader.scss';

function Loader({ className, theme, labels }) {
  let [ isVisible, setVisible ] = React.useState(false);

  window.setTimeout(setVisible, 10, true);

  let classNames = [
    'simple-loader',
    theme || '',
    className || '',
    isVisible ? 'simple-loader-visible' : ''
  ];

  return (
    <div className={classNames.join(' ')}>
      <div className="simple-loader-icon fad fa-sync rotate-center" />
      <div>{labels.common.loading}</div>
    </div>
  );
}

Loader.propTypes = {
  labels: PropTypes.object.isRequired,
  theme: PropTypes.string,
  className: PropTypes.string,
};

export default Loader;
