import React from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import * as config from 'data/config/config';
import 'react-image-crop/dist/ReactCrop.css';

import './ImageCrop.scss';

const icConfig = config.IMAGE_UPLOAD.DISPLAY_IMAGE_OPTIONS;

class ImageCrop extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      crop: {
        unit: 'px',
        aspect: icConfig.cropRatio,
        width: icConfig.cropInitialWidth,
        minWidth: icConfig.cropMinWidth,
      },
    };

    this.onImageLoaded = this.onImageLoaded.bind(this);
    this.onCropChange = this.onCropChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  onImageLoaded(image) {
    this.imageRef = image;
  }

  onCropChange(crop) {
    this.setState({ crop });
  }

  onCancel() {
    this.props.onCancel();
  }

  onConfirm() {
    const fileName = `croppped-image-${new Date().getTime()}.jpg`;
    const { crop } = this.state;
    
    const canvas = document.createElement('canvas');
    const scaleX = this.imageRef.naturalWidth / this.imageRef.width;
    const scaleY = this.imageRef.naturalHeight / this.imageRef.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      this.imageRef,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    const MIME_TYPE = 'image/jpeg';

    canvas.toBlob((blob) => {
      if (!blob) {
        console.error('Canvas is empty');
        return;
      }
      blob.name = fileName;

      window.URL.revokeObjectURL(this.fileUrl);
      this.fileUrl = window.URL.createObjectURL(blob);
      this.props.onResult(this.fileUrl, blob.size, blob.type, fileName);
    }, MIME_TYPE);
  }

  render() {
    const { src } = this.props;

    return (
      <div className="ic-container">
        {/* DOC: https://github.com/DominicTobias/react-image-crop */}
        <ReactCrop
          src={src}
          crop={this.state.crop}
          onChange={this.onCropChange}
          onImageLoaded={this.onImageLoaded}
          onComplete={this.onCropComplete}
        />
        <div className="ic-buttons">
          <div className="ic-cancel fad fa-times-circle" onClick={this.onCancel} />
          <div className="ic-confirm fad fa-check-circle" onClick={this.onConfirm} />
        </div>
      </div>
    );
  }
}

ImageCrop.propTypes = {
  src: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onResult: PropTypes.func.isRequired,
};

export default ImageCrop;
